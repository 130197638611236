<script setup lang="ts">
import { useAppStore } from '@/stores/app'

import { usePanelsStore } from '@/stores/panels'
import { useSessionStore } from '@/stores/session'

import { useModalStore } from '@/stores/modal'

const $modal = useModalStore()

const { ww } = useWindow()

const $panels = usePanelsStore()
const $session = useSessionStore()
const $app = useAppStore()

const { ss, uiAvailable } = useDMOSession()
const { activeDeviceMedia } = useEnvironment()

const uiDebounce = ref(false)

watch(activeDeviceMedia, (device) => {
  if (device) {
    setTimeout(() => {
      uiDebounce.value = true
    }, 500)
  }
}, {
  immediate: true,
})

useHead({
  script: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/lottie-player/1.4.3/lottie-player.min.js',
      type: 'text/javascript',
      defer: true,
    },
    {
      src: 'https://fast.wistia.com/assets/external/E-v1.js',
      async: true,
      defer: true,
    },
  ],
})

onMounted(() => {
  const sessionID = useCookie('session_id')
  if (!sessionID.value)
    $session.logout()
})

const sessionState = computed(() => $app.sessionState)

// Global Listeners
// ----------------
const { $api, $SE } = useNuxtApp()

// Whenever the session state changes, update the backend

watch(sessionState, async (state) => {
  if (state === 'session-complete-consideration')
    $session.unlockMode = true

  if ($session.traits.length > 0 && !$app.isPreSessionStart) {
    // If the session is complete, and the user has no TAIS remaining, then we need set unlock mode to true
    if ($session.record.taisRemaining === 0 && state !== 'session-complete-consideration') {
      $app.sessionState = 'session-complete-consideration'
      $session.unlockMode = true
    }
  }

  if ($app.initialised && $session.initialised) {
    const sessionID = useCookie('session_id')
    if (sessionID.value) {
      await $api.session.updateSessionState({ state }).then(() => {
      // Success
      }).catch((error: any) => {
        $SE(error, {
          extra: {
            message: `Problem updating session state - ${error.message}`,
            state,
          },
        })
      })
    }
    else {
      $session.logout()
    }
  }
}, {
  immediate: true,
})

onUnmounted(() => {
  $modal.close()
})

const { isDesktop } = useWindow()
const { $env, env } = useEnvironment()
watch(isDesktop, (desktop) => {
  if (desktop && env.value)
    $env.processLayout('session')
}, {
  immediate: true,
})

// App State change
</script>

<template>
  <main class="canvas-full layout-session">
    <div id="mpdmodal" />

    <!-- <div class="session-state">
      {{ ss }}
      uiAvailable - {{ uiAvailable }}
    </div> -->

    <!-- <div class="sec-debug">
      {{ ww }}
    </div> -->

    <Transition name="loading" mode="out-in">
      <div v-if="!uiAvailable" key="loading" class="state loading">
        <span class="loader">
          <Spinner :active="true" color="#00ABE9" size="lg" />
        </span>
      </div>
    </Transition>
    <div v-if="uiAvailable && activeDeviceMedia" class="state available">
      <LazyLayoutSessionMobile v-if="uiDebounce && activeDeviceMedia === 'mobile'" key="session-mobile">
        <slot />
      </LazyLayoutSessionMobile>
      <LazyLayoutSessionDesktop v-if="uiDebounce && activeDeviceMedia === 'desktop'" key="session-desktop">
        <slot />
      </LazyLayoutSessionDesktop>
    </div>
  </main>
</template>

<style lang="scss">
@import '@/assets/scss/mq.scss';
@import '@/assets/scss/components/tippy.scss';

.sec-debug {
  position: fixed;
  top:0;
  left:0;
  background:red;
  color:white;
  z-index: 10;
}

.loading-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.loading-enter-active {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.loading-leave {
  opacity: 1;
  transition-delay: 300ms;
}
.loading-leave-active {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.layout-session {
  position: relative;
  .session-state {
    position: absolute;
    top:0;
    left:0;
    background:red;
    color:white;
    z-index:50;
  }

  >.state {
    width:100%;
    height:100%;
    &.loading {
      position: absolute;
      width: 100vw;
      height: 100vh;
      background:white;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .page {
    // height:100%;

    &.dash-scroll-setup {

      display:flex;
      flex-direction:column;
      .sec-content-container {
        position: relative;
        flex:1;
        overflow:auto;

        >.inner {
          height:100%;

          .scroll-container {

          }
        }
      }
    }
  }
}
</style>
